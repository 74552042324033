import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { axiosAPI } from "../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  isToday,
} from "date-fns";

export default function DesiredIndustry() {
  const redirect = useNavigate();
  const [selectedChip, setSelectedChip] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [postData, setPostData] = useState({
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
    isMoreIndustry: true,
  });
  const [industryJobs, setIndustryJobs] = useState([]);

  const Labels = useSelector((state) => state.allLabels.labels);
  const allList = useSelector((state) => state.allList.list);

  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = new Date(createdOn);

    if (isToday(createdDate)) {
      const diffInHours = differenceInHours(dateNow, createdDate);
      if (diffInHours < 1) {
        const diffInMinutes = differenceInMinutes(dateNow, createdDate);
        return `${diffInMinutes} ${Labels.minAgo}`;
      } else {
        return `${diffInHours} ${
          diffInHours === 1 ? Labels.hourAgo : Labels.hoursAgo
        }`;
      }
    }

    const diffInDays = differenceInDays(dateNow, createdDate);

    if (diffInDays < 1) {
      const diffInHours = differenceInHours(dateNow, createdDate);
      if (diffInHours < 1) {
        const diffInMinutes = differenceInMinutes(dateNow, createdDate);
        return `${diffInMinutes} ${Labels.minAgo}`;
      } else {
        return `${diffInHours} ${
          diffInHours === 1 ? Labels.hourAgo : Labels.hoursAgo
        }`;
      }
    } else if (diffInDays == 1) {
      return Labels.Yesterday;
    } else if(diffInDays >1 && diffInDays <30){
      return `${diffInDays} ${
        diffInDays === 1 ? Labels.dayago : Labels.daysago
      }`;
    }
    else if(diffInDays >30){
      const diffInMonths = differenceInMonths(dateNow, createdDate);
      return `${diffInMonths} ${ diffInMonths === 1 ? Labels.monthAgo : Labels.monthsAgo}`;
    }
  };

  const getDesiredIndustryJobs = async (category) => {
    setLoading(true);
    setPostData({
      search: category?.name,
      industryId: category?.id,
      isMoreIndustry: true,
    });
    setSelectedChip(category.id);

    const result = await axiosAPI.post("/desiredIndustryJobs", {
      category: category.id,
    });
    
    const { status, data } = result.data;
    if (status) {
      setIndustryJobs([]);
      setIndustryJobs(data);
      setLoading(false);
    }
  };

  const setJobList = () => {
    setSelectedChip(0);
    setIndustryJobs(
      allList?.industryBasedJobs ? allList?.industryBasedJobs : []
    );
    setLoading(false);
  };

  useEffect(() => {
    setJobList();
  }, [allList]);

  // const viewDetailedJob = (jobId) => {
  //   redirect("/jobSearchPrelogin", { state: jobId });
  // };
  const searchJobs = (e, data) => {
    e.preventDefault();
    redirect(`/jobSearchPrelogin/${data.jobid}`);
  };
  const searchJobByMoreIndustry = (e) => {
    e.preventDefault();
    const postData = {
      // search: "",
      city: "",
      isMoreIndustry: true,
    };
    redirect("/jobSearchPrelogin", { state: postData });
  };

  const searchJobByIndustry = (e) => {
    e.preventDefault();
    redirect("/jobSearchPrelogin", { state: postData });
  };

  return (
    <Stack sx={{ mb: 8 }}>
      {/* -------------------Heading------------------ */}
      <Stack direction="row" justifyContent="center" spacing={2} mb={4}>
        <Typography
          sx={{
            fontSize: { xs: "1.7rem", lg: "2.5rem" },
            fontWeight: "600",
            pl: 6,
            color: "#BA8C04",
          }}
        >
          {Labels?.Desired}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "1.7rem", lg: "2.5rem" },
            fontWeight: "600",
            color: "#1C614E",
            mb: { xs: "10px", md: "0px" },
            mb: 2,
          }}
        >
          {Labels?.Industry}
        </Typography>
      </Stack>
      {/* ---------------------Industry--------------------- */}
      <Box
        sx={{
          pb: 3,
          display: {
            xs: "block",
            xl: "flex",
          },
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          mb: 4,
        }}
      >
        <Chip
          variant="outlined"
          label={Labels.All}
          sx={{
            mb: 1,
            mr: 1,
            color: selectedChip === 0 ? "white" : "black",
            background: selectedChip === 0 ? "#1C614E" : "white",
            fontWeight: selectedChip === 0 ? "500" : "400",
            height: 40,
            fontSize: { xs: "14px", md: "18px" },
            lineHeight: "30px",
            fontStyle: "normal",
            borderRadius: "20px",
            border: "1px solid #000",
            minWidth: "165px",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0px 0px 3px 0px #1C614E",
              color: "#000",
              background:
                selectedChip === 0 ? "#1C614E" : "rgba(0, 0, 0, 0.04)",
            },
          }}
          onClick={() => setJobList()}
        />
        {allList?.businessCategory && allList?.businessCategory.length > 8
          ? allList?.businessCategory.slice(0, 7).map((val, index) => {
              return (
                <Chip
                  variant="outlined"
                  label={val.name}
                  key={index}
                  className="custom-chip"
                  sx={{
                    mb: 1,
                    mr: 1,
                    color: selectedChip === val.id ? "white" : "black",
                    background: selectedChip === val.id ? "#1C614E" : "white",
                    fontWeight: selectedChip === val.id ? "500" : "400",
                    height: 40,
                    fontSize: { xs: "14px", md: "18px" },
                    lineHeight: "30px",
                    fontStyle: "normal",
                    borderRadius: "20px",
                    border: "1px solid #000",
                    minWidth: "165px",
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "0px 0px 3px 0px #1C614E",
                      color: "#000",
                      background:
                        selectedChip === 0 ? "#1C614E" : "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                  onClick={(e) => getDesiredIndustryJobs(val)}
                />
              );
            })
          : allList?.businessCategory &&
            allList?.businessCategory.map((val, index) => {
              return (
                <Chip
                  variant="outlined"
                  label={val.name}
                  key={index}
                  sx={{
                    mb: 1,
                    mr: 1,
                    color: selectedChip === val.id ? "white" : "black",
                    background: selectedChip === val.id ? "#1C614E" : "white",
                    fontWeight: selectedChip === val.id ? "500" : "400",
                    height: 40,
                    fontSize: { xs: "14px", md: "18px" },
                    lineHeight: "30px",
                    fontStyle: "normal",
                    borderRadius: "20px",
                    border: "1px solid #000",
                    minWidth: "165px",
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "0px 0px 3px 0px #1C614E",
                      background:
                        selectedChip === 0 ? "#1C614E" : "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                  onClick={(e) => getDesiredIndustryJobs(val)}
                />
              );
            })}
        {allList?.businessCategory && allList?.businessCategory.length > 8 && (
          <Typography
            sx={{
              fontSize: "18px",
              cursor: "pointer",
              mb: 1,
              fontWeight: "500",
            }}
            onClick={(e) => searchJobByMoreIndustry(e)}
          >
            +{allList?.businessCategory.length - 7} {Labels.More}
          </Typography>
        )}
      </Box>
      {/* ---------------------Industry Jobs--------------------- */}
      {isLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height="13rem"
              sx={{ borderRadius: "17px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height="13rem"
              sx={{ borderRadius: "17px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height="13rem"
              sx={{ borderRadius: "17px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height="13rem"
              sx={{ borderRadius: "17px" }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {industryJobs.length !== 0 ? (
            industryJobs.map((jobs, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Paper
                  sx={{
                    border: "1px solid rgba(43, 73, 71, 0.22)",
                    boxShadow: "none",
                    borderRadius: "17px",
                    "&:hover": {
                      boxShadow:
                        "0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2)",
                    },
                    cursor: "pointer",
                    mb: 3,
                  }}
                  onClick={(e) => searchJobs(e, jobs)}
                >
                  <Card
                    sx={{
                      boxShadow: "none",
                      p: "16px 16px 0px 16px",
                      borderRadius: "17px",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        src={`${PUBLIC_IMAGE_FOLDER}/${jobs?.catIcon}`}
                        sx={{ mr: 1 }}
                      />
                      <Typography sx={{ color: "rgba(100, 100, 100, 0.87)" }}>
                        {jobs?.category}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: "1.3rem",
                        my: 2,
                        height: "32px",
                        overflow: "hidden",
                      }}
                    >
                      {jobs?.jobName.length > 20
                        ? jobs?.jobName.slice(0, 20) + "..."
                        : jobs?.jobName}
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <Stack direction="row" alignItems="center">
                        <PlaceIcon
                          sx={{
                            color: "#1C614E",
                            fontSize: "1.3rem",
                            ml: "-4px",
                          }}
                        />
                        <Typography>
                          {jobs?.address.length > 10
                            ? jobs.address.slice(0, 10) + "..."
                            : jobs?.address}
                        </Typography>
                      </Stack>
                      {/* <Typography>{jobs?.types}</Typography> */}
                      <Chip
                        label={jobs?.types}
                        // size="small"
                        sx={{
                          color: "#BA8C04",
                          background: "#BA8C040F",
                          borderRadius: 1,
                          width: 140,
                          margin: "10px 10px 10px 0",
                          border: "1px solid rgba(0, 0, 0, 0.10)",
                        }}
                      />
                    </Stack>
                  </Card>
                  <Divider sx={{ my: 1 }} />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    pb={2}
                  >
                    <Box sx={{ pt: 1 }}>
                      <Typography sx={{ color: "rgba(0, 0, 0, 0.68)", mb: 1 }}>
                        {getTimeDifference(jobs?.creatdOn)}
                      </Typography>
                      <Typography sx={{ height: "30px", overflow: "hidden" }}>
                        {jobs?.company.length > 23
                          ? jobs?.company.slice(0, 23) + "..."
                          : jobs?.company}
                      </Typography>
                    </Box>
                    <Avatar
                      src={`${jobs?.companyLogo}`}
                      sx={{ borderRadius: "15%" }}
                    />
                  </Stack>
                </Paper>
              </Grid>
            ))
          ) : (
            <Stack direction="row" justifyContent="center" width="100%">
              <Typography>{Labels.noJobsAvailable}</Typography>
            </Stack>
          )}
          <Stack direction="row" justifyContent="center" width="100%">
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              sx={{
                mt: 2,
                borderRadius: "20px",
                color: "#1C614E",
                border: "1px solid #1C614E",
                textTransform: "none",
                "&:hover": {
                  border: "1px solid #1C614E",
                },
                mr: "20px",
              }}
              onClick={searchJobByIndustry}
            >
              {Labels?.viewAll}
            </Button>
          </Stack>
        </Grid>
      )}
    </Stack>
  );
}
